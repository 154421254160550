"use client";

import { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useSearchParams } from "next/navigation";

import { Box } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

import { CommonLayout } from "@/components/Layout";
import ChannelTalkComponent from "@/components/landing/ChannelTalkComponent";
import FloatingButton from "@/components/landing/FloatingButton";
import Footer from "@/components/landing/Footer";
import LandingHeaderSection from "@/components/landing/LandingHeaderSection";
import Section1 from "@/components/landing/v2/Section1";
import Section1_1 from "@/components/landing/v2/Section1_1";
import Section2 from "@/components/landing/v2/Section2";
import Section2_1 from "@/components/landing/v2/Section2_1";
import Section2_3 from "@/components/landing/v2/Section2_3";
import Section3 from "@/components/landing/v2/Section3";
import Section3_1 from "@/components/landing/v2/Section3_1";
import Section4 from "@/components/landing/v2/Section4";
import Section4_1 from "@/components/landing/v2/Section4_1";
import Section5 from "@/components/landing/v2/Section5";
import Section12 from "@/components/landing/v2/Section12";
import Section13 from "@/components/landing/v2/Section13";
import Section14 from "@/components/landing/v2/Section14";
import Section14_1 from "@/components/landing/v2/Section14_1";
import Customize from "@/components/landing/v3/Customize";
import { LANDING_BUTTON_LINK, META_COMP_TALLY_LINK, META_TALLY_LINK } from "@/consts/url";
import { useTallyLink } from "@/hooks/useTallyLink";
import { getSocialPlatformType } from "@/utils/lp-util";

const TALLY_LINK_MAP: { [idx: number]: string } = {
    0: LANDING_BUTTON_LINK,
    1: META_COMP_TALLY_LINK,
    2: META_TALLY_LINK,
    3: "https://tally.so/r/w4Bv5o", // c안 부터
    4: "https://tally.so/r/3jbWN9", // d
    5: "https://tally.so/r/3xjyK9", // e
    6: "https://tally.so/r/mZoY0v", // f
    7: "https://tally.so/r/3NDYkb", // g
};

export default function IndexPage() {
    const searchParams = useSearchParams();
    const box1Ref = useRef<HTMLDivElement>(null);
    const adRef = useRef<HTMLDivElement>(null);
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const { tallyLink } = useTallyLink();

    const [headerColor, setHeaderColor] = useState<"white" | "black">("black");
    const [isLandscape, setIsLandscape] = useState<boolean>(false);

    useEffect(() => {
        let from = getSocialPlatformType(searchParams);
        const cfrom = sessionStorage.getItem("cfrom");

        // 만약 쿼리에 없다면
        if (!from) {
            // 그런데 세션 스토리지도 없다면 그냥 나오기
            if (!cfrom) return;
            //  만약 있다면 프롬에 넣기
            from = Number(cfrom);
        }
        // 쿼리에 있었거나, 세션스토리지에 있었던 걸 from 에 넣었다면 해당 값을 set하기
        sessionStorage.setItem("cfrom", String(from));

        const newTallyLink = TALLY_LINK_MAP[from];
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    const mediaQueryList =
        typeof window !== "undefined"
            ? window?.matchMedia("(orientation:landscape)")
            : undefined;

    const handleOrientationChange = () => {
        if (mediaQueryList?.matches === undefined) return;
        setIsLandscape((prev) => mediaQueryList.matches);
    };

    useEffect(() => {
        mediaQueryList?.addEventListener("change", handleOrientationChange);

        return () => {
            mediaQueryList?.removeEventListener("change", handleOrientationChange);
        };
    }, []);

    const handleScroll = (e: any) => {
        if (box1Ref.current === null) return;

        if (window.scrollY >= (box1Ref.current.offsetTop + box1Ref.current.offsetHeight) / 3) {
            setIsShowing(true);
        }

        if (window.scrollY < (box1Ref.current.offsetTop + box1Ref.current.offsetHeight) / 3) {
            setIsShowing(false);
        }

        if (window.scrollY <= box1Ref.current.offsetTop + box1Ref.current.offsetHeight) {
            setHeaderColor("black");
            return;
        }

        setHeaderColor("black");
    };

    useEffect(() => {
        if (box1Ref.current === null) return;
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (adRef.current === null) return;
        const location = adRef.current?.offsetTop;

        if (window.location.href.includes("taghere_ad_7")) {
            window.scrollTo({ top: location });
        }
    }, []);

    return (
        <CommonLayout>
            <Box position={"relative"} sx={{ overflowX: "hidden" }}>
                {/* SECTION: 상단 header */}
                <LandingHeaderSection color={headerColor} tallyLink={tallyLink} />
                <FloatingButton isShowing={isShowing} tallyLink={tallyLink} />
                {/* SECTION: 인트로 화면 */}
                <Box ref={box1Ref}>
                    <Section1 tallyLink={tallyLink} />
                </Box>
                {/* <Section1_1 /> */}
                <Section2 />
                <Box ref={adRef}>
                    <Section2_1 />
                </Box>

                <Section3_1 />
                <Customize />
                <Section4 />
                <Section4_1 />
                <Section3 />
                {/* 편하게 태그만 */}
                <Section5 />
                {/* 매장 운영에만 집중  */}
                <Section2_3 />

                {/* 저희는 도망가지 않아요*/}
                <Section12 />
                <Section13 />

                {/* 태그히어로 손쉽게 시작하기 */}
                <Section14_1 />
                <Footer />
                <ChannelTalkComponent />
            </Box>
        </CommonLayout>
    );
}
